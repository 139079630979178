import Header from "@editorjs/header";
import AttachesTool from '@editorjs/attaches';
import ImageTool from "@editorjs/image";
import files from "@/api/files.js";
import List from '@editorjs/list';
import Alert from 'editorjs-alert';
const Table = require('@editorjs/table');
const Checklist = require('@editorjs/checklist');

const uploader = {
    uploadByFile(file) {
        let fd = new FormData();
        fd.append("file", file)
        return files.store(fd).then((r) => {
            return {
                success: 1,
                file: {
                    url: r.data.link,
                    extension: r.data.type
                }
            };
        });
    },
    uploadByUrl(url) {
        return new Promise((resolve) => {
            resolve({
                success: 1,
                file: {
                    url: url,
                    // any other image data you want to store, such as width, height, color, extension, etc
                }
            });
        })

    }
};

export default {
    holder: 'editorjs',
    i18n: {
        messages: {
            ui: {
                "blockTunes": {
                    "toggler": {
                        "Click to tune": "Нажмите, чтобы настроить",
                        "or drag to move": "или перетащите"
                    },
                },
                "inlineToolbar": {
                    "converter": {
                        "Convert to": "Конвертировать в"
                    }
                },
                "toolbar": {
                    "toolbox": {
                        "Add": "Добавить"
                    }
                }
            },

            /**
             * Section for translation Tool Names: both block and inline tools
             */
            toolNames: {
                "Attachment": "Файл",
                "Alert": "Заметка",
                "Text": "Параграф",
                "Heading": "Заголовок",
                "Image": "Изображение",
                "List": "Список",
                "Warning": "Примечание",
                "Checklist": "Чеклист",
                "Quote": "Цитата",
                "Code": "Код",
                "Delimiter": "Разделитель",
                "Raw HTML": "HTML-фрагмент",
                "Table": "Таблица",
                "Link": "Ссылка",
                "Marker": "Маркер",
                "Bold": "Полужирный",
                "Italic": "Курсив",
                "InlineCode": "Моноширинный",
            },

            tools: {
                "warning": { // <-- 'Warning' tool will accept this dictionary section
                    "Title": "Название",
                    "Message": "Сообщение",
                },
                "link": {
                    "Add a link": "Вставьте ссылку"
                },
                "stub": {
                    'The block can not be displayed correctly.': 'Блок не может быть отображен'
                }
            },

            /**
             * Section allows to translate Block Tunes
             */
            blockTunes: {
                "delete": {
                    "Delete": "Удалить"
                },
                "moveUp": {
                    "Move up": "Переместить вверх"
                },
                "moveDown": {
                    "Move down": "Переместить вниз"
                }
            },
        }
    },
    tools: {
        attaches: {
            class: AttachesTool,
            inlineToolbar: true,
            config: {
                uploader
            }
        },
        alert: Alert,
        checklist: {
            class: Checklist,
            inlineToolbar: true,
        },
        header: {
            class: Header,
            config: {
                placeholder: "Введите заголовок"
            },
            inlineToolbar: true
        },
        table: {
            class: Table,
        },
        list: {
            class: List,
            inlineToolbar: true,
        },

        image: {
            class: ImageTool,
            config: {
                buttonContent: "Выбрать изображение",
                captionPlaceholder: "Заголовок изображения",
                uploader
            }
        }
        // ...
    },
};
